import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import Box from '@material-ui/core/Box'
import Divider from '@material-ui/core/Divider'
import Layout from '../components/layout'
import SEO from '../components/seo'
import { translateText, translateImageCaption } from '../config/translation'

const useStyles = makeStyles(theme => ({
  headerImageWrapper: {
    maxWidth: 1400,
    margin: '0 auto',
  },
  textBlock: {
    textAlign: 'center',
    maxWidth: '640px',
  },
  imgCaption: {
    textAlign: 'center',
    fontSize: 14,
    fontWeight: 300,
  },
}))

const Home = ({ path, lang }) => {
  const classes = useStyles()

  const data = useStaticQuery(graphql`
  query {
    kellioMaine: file(relativePath: { eq: "images/kellio-maine2.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1400) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`)

  return (
    <Layout pageName='Kelly & Helio' activeRoute={ path } lang={ lang }>
      <SEO title="Home" />
      <Box className={ classes.headerImageWrapper }>
        <Img fluid={data.kellioMaine.childImageSharp.fluid} />
      </Box>
      <p className={ classes.imgCaption }>{ translateImageCaption("At Sunset Rock, Southport Island, ME", lang) }</p>
      <Container maxWidth='md'>
        <Container className={classes.textBlock}>
          <Box m="48px" />
          <p>{ translateText("Kelly and Helio were married in a private ceremony in Southport, ME.", lang) }</p>
          <p>{ translateText("Please join us for celebratory food, drinks, and dancing at the reception in Philadelphia, PA.", lang) }</p>
          <Box m="48px" />
          <Divider />
          <Box m="32px" />
          <h2>KELLY WINTER</h2>
          <p>{ translateText("- and -", lang) }</p>
          <h2>‍HELIO HA</h2>
          <Box m="32px" />
          <Divider />
          <Box m="48px" />
          <Box>
          <p>{ translateText("SATURDAY, OCTOBER 12, 2019", lang) }</p>
          <p>{ translateText("AT 6:00PM", lang) }</p>
          <p>Philadelphia School of Circus Arts</p>
          <p>6452 Greene Street</p>
          <p>Philadelphia, PA 19119</p>
          </Box>
          <Box m="48px" />
        </Container>
      </Container>
    </Layout>
  )
}

export default Home
